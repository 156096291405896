import { render, staticRenderFns } from "./comp_dpc-CarouselMultiplePro.vue?vue&type=template&id=28f08c23&scoped=true"
import script from "./comp_dpc-CarouselMultiplePro.vue?vue&type=script&lang=js"
export * from "./comp_dpc-CarouselMultiplePro.vue?vue&type=script&lang=js"
import style0 from "./comp_dpc-CarouselMultiplePro.vue?vue&type=style&index=0&id=28f08c23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f08c23",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VIcon,VRow,VTab,VTabs})
